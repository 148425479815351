.wrapper{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
  }
  
  
.breather{
  position: fixed;
  height: 200px;
  width: 200px;
  top: calc(50% - 35vw);  
  left: calc(50% - 35vw);
  margin: 20px auto 0;
}
    
.breathing--circle{
  background: transparent;
  border: solid 0.1px #f4f4f4;
  // box-shadow: 0px 0px 5vw rgba(0,0,0,0.1);
  opacity: 0.2;
  position: absolute;
  top: calc(50% - 10%);
  left: calc(50% - 10%);
  width: 20%;
  height: 20%;
  border-radius: 50%;
//   -webkit-animation-duration: 8s;
//   -moz-animation-duration: 8s;
//   -o-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
      
.breathing--circle--a{
  z-index: 99;
  transform: scale(1);
  -webkit-animation-name: breathe-a;
  -moz-animation-name: breathe-a;
  -o-animation-name: breathe-a;
  animation-name: breathe-a;
}
.breathing--circle--b{
  z-index: 98;  
  transform: scale(2);
  -webkit-animation-name: breathe-b;
  -moz-animation-name: breathe-b;
  -o-animation-name: breathe-b;
  animation-name: breathe-b;
}
.breathing--circle--c{
    z-index: 97; 
    transform: scale(3); 
    -webkit-animation-name: breathe-c;
    -moz-animation-name: breathe-c;
    -o-animation-name: breathe-c;
    animation-name: breathe-c;
}

  @keyframes breathe-a {
    0%{   transform: scale(1);}  
    50%{  transform: scale(3);}  
    100%{ transform: scale(1);}
  }
  
  @keyframes breathe-b {
    0%{   transform: scale(2);}  
    50%{  transform: scale(3);}  
    100%{ transform: scale(2);}
  }
  
  @keyframes breathe-c {
    0%{   transform: scale(3);}  
    40%{  transform: scale(3.3);}  
    100%{ transform: scale(3);}
  }
  