
.welken__letters, .vermodern__title {
    display: flex;
    align-items: space-around;
    justify-content: space-around;
}

.welken__letters span, .vermodern__title span  {
    display: inline-block;
}


.span--empty {
    color: transparent !important;
}

.welken__title {
    font-family: 'title';
    font-size: 100px;
    height: 100px;
}

.welken__video1 {
    width: 100vw;
    padding: 0 15vw;
    height: 100vh;
    z-index: 1;
    box-sizing: border-box;
}

.welken__video1 video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.welken__video2 {
    display: none;
}

.welken__decay {
    font-family: 'pixel';
    font-size: 30px;
    height: 30px;
    z-index: 2;
}

.welken__decay--2 {
    font-size: 50px;
    height: 50px;
    z-index: 2;
}

.welken__decay--3 {
    font-size: 250px;
    height: 150px;
    z-index: 2;
}

.welken__zyklus {
    font-family: 'reg';
    font-size: 30px;
    height: 30px;
}

.welken__eternal {
    font-family: 'pixel';
    font-size: 5vw;
    height: 5vw;
    color: orangered;
    z-index: 2;
}

.welken__text1 {
    font-family: 'title';
    line-height: 110%;
    width: 100%;
    height: 100%;
    padding: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    box-sizing: border-box;
}

.welken__text1 p{
    width: 1000vw;
}

.welken__slogan2 {
    font-family: 'reg';
    font-size: 30px;
    height: 70px;
}

.welken__slogan3 {
    font-family: 'reg';
    font-size: 30px;
    height: 30px;
}

.welken__slogan4 {
    font-family: sans-serif;
    font-size: 25px;
    height: 30px;
    border: solid 1px orangered;
    padding: 10px 15px 9px 15px;
    color: orangered;
    cursor: pointer;
    text-transform: uppercase;
}

.welken__slogan4:hover {
    transform: scale(1.1);
    transition-duration: .2s;
}

.welken__text2 {
    font-family: monospace;
    text-transform: uppercase;
    font-size: 20px;
    height: 30px;
    width: 800px;
    right: 50px;
    top: 200px;
}

.welken__slogan5 {
    font-family: 'reg';
    font-size: 30px;
    height: 30px;
}

.welken__slogan6 {
    font-family: 'reg';
    font-size: 20px;
    height: 20px;
    width: 100vw;
    text-align: center;
}

.welken__slogan7 {
    font-family: 'reg';
    font-size: 30px;
    height: 30px;
}

.welken__gallery {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 10vh 5vw;
}

.welken__gallery div {
    width: 100%;
    height: 100%;
}

.welken__galleryLeave, .vermoden__hcLeave {
    position: fixed;
    width: 200px;
    border: solid 1px white;
    border-radius: 2px;
    height: 30px;
    top: 30px;
    right: 30px;
    z-index: 2000000;
    cursor: pointer;
    text-align: center;
    line-height: 28px;
    background-color: transparent;
}

.welken__galleryLeave button, .vermoden__hcLeave button {
    background-color: transparent;
    outline: transparent;
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.welken__galleryLeave:hover, .vermoden__hcLeave:hover {
    background-color: rgba(255,255,255,0.2);
    cursor: pointer;
}

@keyframes blink {
    0% { filter: blur(10px); }
    20% { filter: blur(10px); }
    21% { filter: blur(0px); }
    40% { filter: blur(0px); }
    41% { filter: blur(10px); }
    70% { filter: blur(10px); }
    71% { filter: blur(0px); }
    80% { filter: blur(0px); }
    81% { filter: blur(10px); }
    99% { filter: blur(10px); }
    100% { filter: blur(0px); }
}

.welken__slogan6 {
    top:30vh;
    left: 20vw;
    text-align: left;
    font-family: 'reg';
    font-size: 17px;
}
.welken__mouse {
    top:50vh;
    right: 20vw;
    text-align: right;
    font-family: 'reg';
    font-size: 17px;
}
.welken__slogan7 {
    top:70vh;
    left: 20vw;
    font-family: 'reg';
    font-size: 17px;
}

.welken__texthc {
    width: 600px;
    font-family: 'pixel';
    font-size: 30px;
    margin-left: calc(50vw - 300px);
    text-align: center;
    margin-top: -50px;
}