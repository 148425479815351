.mobile {
    display: none;
    background-color: black;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.app {
    z-index: 1;
}

.mobile__title {
    font-size: 70px;
    text-align: center;
    // top: 12vh;
    width: 100%;
    color: transparent;
    position: absolute;
    font-weight: normal;
    -webkit-text-stroke: 1px white;
}

.upload__computer {
    position: absolute;
    bottom: 25vw;
    width: 100%;
    text-align: center;
    font-family: 'reg';
    font-size: 20px;
    line-height: 130%;
    z-index: 1000;
    display: none;
}

.mobile__text {
    position: absolute;
    bottom: 11vw;
    width: 100%;
    text-align: center;
    font-family: 'reg';
    font-size: 5vw;
    line-height: 130%;
}

.mobile__text span {
    display: block;
    font-size: 0.7em;
}

.mobile__plants {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.5;
}

.mobile__plants img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropzone {
    display: none;
}

@media (hover: none) {
    .mobile {
        display: block;
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1000000;
    }

    .mobile__title {
        font-size: 12vw;
        text-align: center;
        top: 12vh;
        width: 100%;
        color: transparent;
        position: absolute;
        font-weight: normal;
        -webkit-text-stroke: 1px white;
    }

    html {
        overflow: hidden;
    }

    .app {
        display: none;
    }

    .dropzone {
        display: block;
    }

    .upload__computer {
        display: none;
    }
}

@media (max-width: 600px) {
    .mobile {
        display: block;
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1000000;
    }

    .mobile__title {
        font-size: 12vw;
        text-align: center;
        top: 12vh;
        width: 100%;
        color: transparent;
        position: absolute;
        font-weight: normal;
        -webkit-text-stroke: 1px white;
    }

    html {
        overflow: hidden;
    }

    .app {
        display: none;
    }

    .dropzone {
        display: block;
    }

    .upload__computer {
        display: none;
    }
}