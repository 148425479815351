/*
 * This file is NOT needed in order to use skrollr.
 * The demo uses it and it may help you as well.
 */


 html, body {
	width:100%;
	height:100%;
	padding:0;
	margin:0;
	overflow-x:hidden;
}

.skrollable {
	/*
	 * First-level skrollables are positioned relative to window
	 */
	position:fixed;

	/*
	 * Skrollables by default have a z-index of 100 in order to make it easy to position elements in front/back without changing each skrollable
	 */
	z-index:100;
}

.skrollr-mobile .skrollable {
	/*
		May cause issues on Android default browser (see #331 on GitHub).
	*/
	position:absolute;
}

.skrollable .skrollable {
	/*
	 * Second-level skrollables are positioned relative their parent skrollable
	 */
	position:absolute;
}

.skrollable .skrollable .skrollable {
	/*
	 * Third-level (and below) skrollables are positioned static
	 */
	position:static;
}
