.app {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: scroll;
  -webkit-font-smoothing: antialiased;
}

.component-wrapper {
  opacity: 0;
  pointer-events: none;
}

body {
  background-color: black;
  background: unset;
  color: white;
}

html {
  overflow: hidden;
  background-color: black;
}


#giant-scroll {
  width: 100vw;
  height: 10000vh;
  background-color: black;
  position: absolute;
}

.section--saen {
  pointer-events: none;
}

.section {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

@font-face {
  font-family: 'reg';
  src:url('../../assets/fonts/Redaction_20-Regular.woff2')
}

@font-face {
  font-family: 'pixel';
  src:url('../../assets/fonts/Redaction_50-Regular.woff2')
}

@font-face {
  font-family: 'title';
  src:url('../../assets/fonts/FHCordelia-Regular.woff')
}

.scrollcount {
  position: fixed;
  z-index: 4000000;
  top: 20px;
  left: 20px;
  background-color: white;
  width: 100px;
  height: 30px;
  color: black;
  font-size: 24px;
  text-align: center;
  border: solid 1px black;
  border-radius: 10px;
}

// video {
//   background-color: rgba(0,0,0,0.5);
// }