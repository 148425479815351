.background__container, .background--inner {
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    pointer-events: none;
    position: fixed;
}

.background--inner {
    opacity: 0.3;
}


.intro__background-1 {
    background-image: url('http://api.tangled-garden.net/assets/4fd464db-f219-436f-8e9a-05f53c6212fe');
    opacity: 1;
}

// Pink abstract one
.background__2 {
    background-image: url('http://api.tangled-garden.net/assets/5de6fa0b-0d8c-4afd-8b18-cf192d81eada');
}

// Green abstract one
.background__4 {
    background-image: url('http://api.tangled-garden.net/assets/8dd896a0-897a-4b32-a371-ad49ea7c138b');
    opacity: 0.3;
}

// Crazy lines 1
.background__5 {
    background-image: url('http://api.tangled-garden.net/assets/bfa2bad8-154a-4729-b6ed-da2d993c79de');
    opacity: 0.2;
}

// Crazy lines 2
.background__3 {
    background-image: url('http://api.tangled-garden.net/assets/43fa269a-6be3-4db8-813a-f2b95369a812');
    opacity: 0.1;
}

// Anemone
.background__6 {
    background-image: url('http://api.tangled-garden.net/assets/30332ab4-d0e5-4925-80af-53e53b807fe2');
    background-color: rgba(29,111,0,0.4);
    opacity: 0.3;
}

// Tiges bleues
.background__7 {
    background-image: url('http://api.tangled-garden.net/assets/82c426f1-8dfd-4c85-b712-20aae40f6553');
    background-color: rgba(255,255,255,0.5);
    opacity: 0.2;
}

// Carte mère
.background__8 {
    background-image: url('http://api.tangled-garden.net/assets/9f19f037-3876-4d28-bfcb-8beb24822690');
    opacity: 0.2;
}

// Branching
.background__9 {
    background-image: url('http://api.tangled-garden.net/assets/254e0f11-d3bc-44a8-a897-59dd473eefbf');
    opacity: 0.2;
}

// Squares
.background__11 {
    background-image: url('http://api.tangled-garden.net/assets/61e0a088-e835-4b2e-806a-e8511144008f');
    opacity: 0.1;
}

// Purple world
.background__10 {
    background-image: url('http://api.tangled-garden.net/assets/ebaaeae9-f46d-466b-b57b-225dc9d3161c');
}

// Abstract multicolor
.background__12 {
    background-image: url('http://api.tangled-garden.net/assets/d4fdd8e0-7698-473f-8c9f-091d40fcd8b4');
    opacity: 0.15;
}