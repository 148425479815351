.vermordern__hypercam {
    width: 100vw;
    height: 100vh;
    padding: 100px;
    box-sizing: border-box;
}

.vermordern__hypercam div, .vermordern__hypercam div iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.vermodern__end {
    animation: float infinite ease-in-out 4s;
    width: 100%;
    height: calc(100vh + 10px);
    background-color: rgba(255,255,255,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'reg';
    font-size: 20px;
}

.vermodern__end p {
    text-align: left;
    width: 620px;
    font-family: 'pixel';
    font-size: 25px;
    margin-top: 50px;
    color: orangered;
}

.vermodern__end span {
    font-family: 'pixel';
    font-size: 30px;
    color: orangered;
    text-transform: u;
    margin-bottom: 30px;
    width: 620px;
}


@keyframes float {
    0% { margin-top: -10px }
    50% { margin-top: 0px }
    100% { margin-top: -10px }
}

.vermodern__title {
    width: 400px;
    font-family: 'title';
    color: white;
    font-size: 100px;
    text-align: center;
    margin-top: 100px;
    margin-left: calc(50vw - 200px);
}
.vermodern__text {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-family: 'reg';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
// p