.intro__wrapper {
    width: calc(25vh + 2px); /* Set the size of the progress bar */
    height: calc(25vh + 2px);
    position: fixed; /* Enable clipping */
    clip: rect(0px, 25vh, 25vh, 12.5vh); /* Hide half of the progress bar */
    z-index: 3;
    pointer-events: none;
    top: 37.5vh;
  }
  /* Set the sizes of the elements that make up the progress bar */
  .circle {
    width: 25vh;
    height: 25vh;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 50%;
    position: absolute;
    clip: rect(0px, 12.5vh, 25vh, 0px);
    box-sizing: border-box;
    top: 0;
    vertical-align: top;
  }

  .circle:nth-child(2) {
    margin-left: 0px;
    margin-top: 0px;
  }
  /* Using the data attributes for the animation selectors. */
  /* Base settings for all animated elements */
  div[data-anim~=base] {
    animation-iteration-count: 1;  /* Only run once */
    animation-fill-mode: forwards; /* Hold the last keyframe */
    animation-timing-function:linear; /* Linear animation */
  }
  
  .intro__wrapper[data-anim~=wrapper] {
    animation-duration: 0.01s; /* Complete keyframes asap */
    animation-delay: 5s; /* Wait half of the animation */
    animation-name: close-wrapper; /* Keyframes name */
  }
  
  .circle[data-anim~=left] {
    animation-duration: 10s; /* Full animation time */
    animation-name: left-spin;
  }
  
  .circle[data-anim~=right] {
    animation-duration: 5s; /* Half animation time */
    animation-name: right-spin;
  }
  /* Rotate the right side of the progress bar from 0 to 180 degrees */
  @keyframes right-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  /* Rotate the left side of the progress bar from 0 to 360 degrees */
  @keyframes left-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* Set the wrapper clip to auto, effectively removing the clip */
  @keyframes close-wrapper {
    to {
      clip: rect(auto, auto, auto, auto);
    }
  }
  