// #blumen {
//     width: 100vw;
//     height: 100vh;
//     top: 0;
//     left: 0;
// }

.blumen__background-3 {
    // background-image: url('https://a.storyblok.com/f/107910/2204x1344/f81ae351e9/background-general.png');
    opacity: 1;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-color: white;
}

.blumen__background-bright {
    z-index: 116;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.blumen__text1 {
    top: 0;
    left: 0;
    font-family: 'reg';
    font-size: 30px;
    text-align: left;
    color: black;
    margin-left: 20px;
    margin-top: 10px;
    line-height: 120%;
    z-index: 117;
    pointer-events: none;
}

.blumen__round {
    width: 150px;
    height: 150px;
    z-index: 117;
    background: radial-gradient(orangered, white);
    background-color: black;
    border-radius: 50%;
    pointer-events: none;
    left: 24vw;
    top: 24vh;
}

.blumen__text2 {
    top: 0;
    left: 0;
    font-family: 'title';
    font-size: 50px;
    text-align: left;
    color: white;
    margin-left: 20px;
    margin-top: 10px;
    line-height: 120%;
    z-index: 118;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.blumen__text2 p {
    height: 300px;
}

// .userphoto--4 {
//     top: 0;
//     left: 0;
//     z-index: 10;
//     width: 100%;
//     height: 100%;
//     opacity: 0;
//     transition-duration: 1s;
//     transition-property: opacity;
//     z-index: 1000;
// }

.blumen__background3__container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.blumen__code {
    width: 600px;
    height: auto;
    cursor: pointer;
    z-index: 1000;
}

.blumen__code img{
    transition-duration: .1s;
}

.blumen__code img:hover {
    transform: scale(1.1);
}

.blumen__text3 {
    font-family: 'pixel';
    font-size: 30px;
    padding: 50px;
    width: 500px;
    line-height: 112%;
    text-align: center;
}

.blumen__text3 p {
    margin: 0;
}

.blumen__video1, .blumen__video2 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 50px;
    box-sizing: border-box;
    z-index: 100000;
}

.blumen__video1 video, .blumen__video2 video{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.blumen__garden {
    width: 5000px;
    height: 5000px;
    right: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
}

.blumen__garden__flower {
    width: 90px;
    position: absolute;
    height: fit-content
}

.blumen__garden__flower--src1 {
    width: 70px;
}

.blumen__garden__flower--src0 {
    width: 70px;
}

.blumen__garden__flower--src2 {
    width: 50px;
}

.blumen__garden__flower--src3 {
    width: 80px;
}

.blumen__garden__flower--src4 {
    width: 40px;
}


.blumen__garden__p {
    font-size: 17px;
    color: lightgrey;
    font-family: 'reg' !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
}

// .blumen__garden__p--1 {
//     left: 90vw;
//     top: -10vh;
// }

// .blumen__garden__p--2 {
//     left: 3500px;
//     top: 3500px;
// }

// .blumen__garden__p--3 {
//     left: 2600px;
//     top: 2000px;
// }

// .blumen__garden__p--4 {
//     left: 2900px;
//     top: 2400px;
// }

.blumen__text4 {
    width: 100000px;
    top: 0vh;
    font-size: 100vh;
    color: rgba(255,255,255,0.2);
    -webkit-text-stroke: 2px white;
    font-family: 'pixel';
    opacity: 0.5;
    z-index: 2;
    line-height: 100vh;
}

.blumen__video2 {
    z-index: 1;
    padding: 50px;
}

.blumen__text6 {
    right: 0;
    padding: 50px;
    font-size: 20px;
    width: 600px;
    font-family: monospace;
}

.blumen__title {
    width: 100vw;
    height: 100vh;
    font-size: 100px;
    font-family: 'title';
    text-align: center;
    left: 0;
    top: 0;
    line-height: 100vh;
}

.blumen__floater {
    width: 150px;
}

.blumen__floater--1 {
    width: 300px;
}

.blumen__floater--2 {
    width: 400px;
}


.blumen__floater--3 {
    width: 200px;
    opacity: 0.5;
}

.userphoto--4 {
    transition-duration: 0.5s;
    transition-property: opacity;
}