.video__time {
    width: 100%;
    height: 20px;
    bottom: 2px;
    left: 0;
    position: fixed;
    z-index: 20;
    opacity: 0;
    z-index: 10000000;
}

.video__time__cursor {
    width: 0px;
    left: 0;
    border-bottom: solid 2px white;
    height: 20px;
    animation: cursor linear 123s;
    position: fixed;
}

.video__time__cursor p {
    text-align: right;
    font-size: 13px;
    font-family: 'reg';
    margin-top: 0;
    margin-left: 3px;
}

@keyframes cursor {
    0% { width: 0%; }
    100% { width: 100%; }
}