.scroll-indicator {
    width: 40px;
    height: 40px;
    border: solid 1px white;
    border-radius: 25px;
    top: 20px;
    left: 18px;
    position: fixed;
    z-index: 30;
    box-sizing: border-box;
    padding-top: 16px;
    padding-left: 15px;
    transform: rotate(-90deg);
    transition-duration: 1s;
}

.si__dot {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
}